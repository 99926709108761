import React from "react";
import "../css/education.css";
const Education = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <h4 style={{ alignSelf: "center", paddingRight: 16, paddingTop: 16 }}>
        EDUCATION
      </h4>
      <hr style={{ color: "black", alignSelf: "flex-end", width: 200 }} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p
            style={{
              color: "black",
              fontWeight: "bold",
              fontSize: 24,
            }}
          >
            Level 5 Diploma
          </p>
          <p style={{ color: "darkgray", padding: 0 }}>
            BUSINESS INFORMATION TECHNOLOGY
          </p>
          <p style={{ color: "darkgray" }}>University Of Greenwich</p>

          <hr />
          <p
            style={{
              color: "black",
              fontWeight: "bold",
              fontSize: 24,
            }}
          >
            BA (History)
          </p>
          <p style={{ color: "darkgray" }}>Dagon University</p>

          <hr />
          <p
            style={{
              color: "black",
              fontWeight: "bold",
              fontSize: 24,
            }}
          >
            Android Kotlin Developer
          </p>
          <p style={{ color: "darkgray", padding: 0 }}>NANODEGREE</p>
          <p style={{ color: "darkgray" }}>Udacity</p>
          <a
            style={{ color: "#42d7f5" }}
            target={"_blank"}
            href="https://confirm.udacity.com/6EYUDHQV"
          >
            Cetificate
          </a>
          <hr />
        </div>
      </div>
    </div>
  );
};

export default Education;
