import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../css/heading.css";
const Heading = () => {
  return (
    <div>
      <Row style={{ margin: 0 }}>
        <Col md={5} style={{ display: "flex", height: 250, padding: 0 }}>
          <div
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              backgroundColor: "white",
            }}
          >
            <img
              style={styles.circleImage}
              src={require("../image/profile.jpg")}
              width={150}
              height={150}
            />
          </div>
        </Col>
        <Col style={{ display: "flex", backgroundColor: "black" }} md={7}>
          <div
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              display: "flex",
            }}
          >
            <h1 style={{ color: "white" }}>HEIN HTET</h1>
            <p style={{ color: "whitesmoke" }}>Mobile Application Developer</p>
            <span
              className="spanPresent"
              style={{
                padding: 16,
                color: "gray",
                bottom: 0,
                position: "relative",
              }}
            >
              2017 - present
            </span>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const styles = {
  circleImage: {
    borderRadius: "50%",
    border: "solid",
    borderColor: "black",
    borderWidth: 2,
  },
  cstyle: {
    margin: "0px",
    padding: "0px",
    border: "0px",
    maxWidth: "100%",
  },
};

export default Heading;
