import React from "react";
import { Col, Row } from "react-bootstrap";

const RelatedProject = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
      }}
    >
      <h2
        style={{
          marginTop : 24,
          marginBottom : 24,
          paddingRight: 16,
          paddingTop: 16,
          paddingBottom: 16,
          paddingLeft: 16,
          textAlign : 'center'
        }}
      >
        RELATED PROJECTS
      </h2>
      <Row>
        <Col md={4} sm={6}>
          <Project
            link={
              "https://play.google.com/store/apps/details?id=th.co.pi.financial&hl=en&gl=US"
            }
            title="PI Financial"
            icon={require("../image/pi.png")}
          />
        </Col>
        <Col md={4} sm={6}>
          <Project
            link={
              "https://play.google.com/store/apps/details?id=com.ktc.eapplication.app&hl=en&gl=US"
            }
            title="GetKTC"
            icon={require("../image/get_ktc.png")}
          />
        </Col>
        <Col md={4} sm={6}>
          <Project
            link={
              "https://play.google.com/store/apps/details?id=com.tapktc.app&hl=en&gl=US"
            }
            title="KTC Mobile"
            icon={require("../image/ktc.png")}
          />
        </Col>
      </Row>
      <br/>
      <Row>
        <Col md={4} sm={6}>
          <Project
            link={
              "https://play.google.com/store/apps/details?id=com.hungrynow.customer&hl=en_IN"
            }
            title="HungryNow"
            icon={require("../image/hungrynow_logo.png")}
          />
        </Col>
        <Col md={4} sm={6}>
          <Project
            link={
              "https://play.google.com/store/apps/details?id=com.extraspaceasia.loyalty&hl=en_IN"
            }
            title="Extra Space"
            icon={require("../image/extraspace.png")}
          />
        </Col>
        <Col md={4} sm={6}>
          <Project
            link={
              "https://play.google.com/store/apps/details?id=sg.com.seveneleven.sevenrewards"
            }
            title="7Rewards"
            icon={require("../image/7eleven.png")}
          />
        </Col>
      </Row>
      <br/>
      <Row>
        <Col md={4} sm={6}>
          <Project
            link={
              "https://play.google.com/store/apps/details?id=sg.com.woodlandstransport.shuttle.passenger"
            }
            title="SSC Shuttle Bus"
            icon={require("../image/shuttlebus.png")}
          />
        </Col>
        <Col md={4} sm={6}>
          <Project
            link={
              "https://play.google.com/store/apps/details?id=sg.com.woodlandstransport.parent.production"
            }
            title="WTS School Bus"
            icon={require("../image/wts.png")}
          />
        </Col>
        <Col md={4} sm={6}>
          <Project
            link={
              "https://play.google.com/store/apps/details?id=com.pv.kiwimechanic"
            }
            title="Kiwi Mechanic"
            icon={require("../image/kiwi.png")}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4} sm={6}>
          <Project
            link={
              "https://play.google.com/store/apps/details?id=com.abccontent.mahartv&hl=en&gl=US"
            }
            title="Mahar"
            icon={require("../image/mahar.png")}
          />
        </Col>
        <Col md={4} sm={6}>
          <Project
            link={
              "https://play.google.com/store/apps/details?id=com.lodggy.app"
            }
            title="Lodggy"
            icon={require("../image/lodggy.png")}
          />
        </Col>
        <Col md={4} sm={6}>
          <Project
            link={
              "https://play.google.com/store/apps/details?id=com.apex.aungmyin&hl=en&gl=US"
            }
            title="Aung Myin"
            icon={require("../image/aungmyin.png")}
          />
        </Col>
      </Row>
    </div>
  );
};

interface Props {
  title: string;
  icon: string;
  link: string;
}

const Project = (props: Props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        justifyItems: "center",
        marginTop: 16,
      }}
    >
      <a
        href={props.link}
        target={"_blank"}
        style={{
          textDecoration: "none",
        }}
      >
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <img
            src={props.icon}
            style={{
              alignSelf: "center",
              borderRadius: "50%",
              textAlign: "center",
            }}
            width={100}
            height={100}
          />
          <p
            style={{
              color: "black",
              textAlign : 'center'
            }}
          >
            {props.title}
          </p>
        </div>
      </a>
    </div>
  );
};

export default RelatedProject;
