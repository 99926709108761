import React from "react";
import { Col, Row } from "react-bootstrap";
import Education from "./Education";
import "../css/education.css";
import Skill from "./Skill";
import Work from "./Work";

const EducationAndWork = () => {
  return (
    <div>
      <Row style={{ margin: 0 }}>
        <Col
          style={{ paddingTop: 16, padding: 0, backgroundColor: "white" }}
          md={5}
        >
          <Education />
          <Skill />
        </Col>
        <Col
          md={7}
          style={{
            margin: 0,
            padding: 0,
          }}
        >
          <Work />
        </Col>
      </Row>
    </div>
  );
};

export default EducationAndWork;
