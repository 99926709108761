import React from "react";
import { Col, Row } from "react-bootstrap";
import SocialMediaTab from "./SocialMediaTab";
import { FiGithub, FiGlobe, FiFacebook, FiTwitter } from "react-icons/fi";

const SocialMedia = () => {
  return (
    <div style={{ padding: 16, backgroundColor: "#403b41" }}>
      <Row>
        <Col lg={3} md={6}>
          <SocialMediaTab
            icon={() => <FiGithub style={{ color: "white" }} />}
            title={"Github"}
            link={"https://github.com/HeinXtet"}
          />
        </Col>
        <Col lg={3} md={6}>
          <SocialMediaTab
            icon={() => <FiGlobe style={{ color: "white" }} />}
            title={"Website"}
            link={"https://heinhtet.dev/"}
          />
        </Col>
        <Col lg={3} md={6}>
          <SocialMediaTab
            icon={() => <FiFacebook style={{ color: "white" }} />}
            title={"Facebook"}
            link={"https://www.facebook.com/moe.satt.77/"}
          />
        </Col>
        <Col lg={3} md={6}>
          <SocialMediaTab
            icon={() => <FiGithub style={{ color: "white" }} />}
            title={"Twitter"}
            link={"https://twitter.com/DeevdHtet"}
          />
        </Col>
      </Row>
    </div>
  );
};

export default SocialMedia;
