import React from "react";

const Work = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <h4
        style={{
          alignSelf: "start",
          paddingRight: 16,
          paddingTop: 16,
          marginLeft: 16,
          color: "white",
        }}
      >
        WORKS
      </h4>
      <hr
        style={{
          color: "white",
          alignSelf: "flex-start",
          width: 200,
          marginLeft: 16,
        }}
      />
      <WorkItem
        title={"Senior Android Developer"}
        companyName="Sirius Technologies/Bangkok"
        duration="06/2022 - Present"
        description=""
        perform={() => (
          <div
            className="workItem"
            style={{ paddingLeft: 16, paddingRight: 16 }}
          >
            <ul>
              <li>Performed development as an Senior android developer.</li>
              <li>
                Performed technical designs and implementation of various
                complex mobile apps.
              </li>
              <li>Upgrade and apply modern architecture on existing apps.</li>
              <li>
                Handle the development lifecycle of the developing applications.
              </li>
              <li>
                Developed new native android applications, analytics requirement
                and implement designs, UI/UX and applied business logic what is
                company requirements.
              </li>
              <li>
                Collaborate with other developers, designers, and project
                managers to develop new features consistent with the project
                roadmap.
              </li>
              <li>Performed performance turning to improve the app.</li>
              <li>
                Shared knowledge and experiences with OTJ developers and juniors
                to improve their skills set.
              </li>
            </ul>
            <hr style={{ color: "white" }} />
          </div>
        )}
      />
      <WorkItem
        title={"Senior Android Developer"}
        companyName="HungryNow/Bangkok"
        duration="07/2021 - 06/2022"
        description=""
        perform={() => (
          <div
            className="workItem"
            style={{ paddingLeft: 16, paddingRight: 16 }}
          >
            <ul>
              <li>Performed development as an android developer.</li>
              <li>
                Performed technical designs and implementation of various
                complex mobile apps.
              </li>
              <li>Upgrade and apply modern architecture on existing apps.</li>
              <li>
                Handle the development lifecycle of the developing applications.
              </li>
              <li>
                Developed new native android applications, analytics requirement
                and implement designs, UI/UX and applied business logic what is
                company requirements.
              </li>
            </ul>
            <hr style={{ color: "white" }} />
          </div>
        )}
      />
      <WorkItem
        title={"Senior Android Developer/Lead React Native Developer"}
        companyName="Codigo/Sigapore"
        duration="07/2019 - 07/2021"
        description=""
        perform={() => (
          <div
            className="workItem"
            style={{ paddingLeft: 16, paddingRight: 16 }}
          >
            <ul>
              <li>
                Performed development as an android developer and lead Hybird
                application developer (React Native).
              </li>
              <li>
                Performed technical designs and implementation of various
                complex mobile apps.
              </li>
              <li>
                Reviewed requirements, designs and wireframes for technical
                feasibility.
              </li>
              <li>
                Collaborated with the scrum team to translate client
                requirements into implementable user stories.
              </li>
              <li>
                Worked closely with product managers and designers to define a
                rich Mobile experience for the user.
              </li>
              <li>
                Contribute modern android architecture which is used in company.
              </li>
              <li>
                Handle the development lifecycle of the developing applications.
              </li>
              <li>
                Developed new native android applications, analytics requirement
                and implement designs, UI/UX and applied business logic what is
                client requirements.
              </li>
              <li>
                Maintained existing projects, and applied new rich features.
              </li>
            </ul>
            <hr style={{ color: "white" }} />
          </div>
        )}
      />
      <WorkItem
        title={"Android Developer"}
        companyName="Comquas/Myanmar"
        duration="07/2017 – 06/2019 "
        description=""
        perform={() => (
          <div
            className="workItem"
            style={{ paddingLeft: 16, paddingRight: 16 }}
          >
            <ul>
              <li>
                Applied UI/UX designer/creative vision and design to Android/IOS
                native applications.
              </li>
              <li>
                Collaborate with other developers, designers, and project
                managers to develop new features consistent with the project
                roadmap.
              </li>
              <li>Performed performance turning to improve the app.</li>
              <li>
                Shared knowledge and experiences with OTJ developers and juniors
                to improve their skills set.
              </li>
              <li>
                Use efficient third-party libraries such as ReactiveX, Firebase
                and have well knowledge about new android architecture
                components.
              </li>
            </ul>
            <hr style={{ color: "white" }} />
          </div>
        )}
      />
    </div>
  );
};

interface Props {
  title: string;
  companyName: string;
  duration: string;
  description: string;
  perform: () => React.ReactChild;
}
const WorkItem = (props: Props) => {
  return (
    <div style={{ paddingLeft: 16 }}>
      <p style={{ color: "white", fontWeight: "bold" }}>{props.title}</p>
      <p style={{ color: "white", display: "block" }}>{props.companyName}</p>
      <p style={{ color: "gray", display: "block" }}>{props.duration}</p>
      <p style={{ color: "gray" }}>{props.description}</p>
      {props.perform()}
    </div>
  );
};

export default Work;
