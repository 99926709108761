import React from "react";
import "../css/social_media.css";

interface Props {
  title: string;
  icon: () => React.ReactChild;
  link: string;
}
const SocialMediaTab = (props: Props) => {
  return (
    <div
      style={{
        padding: 8,
        display: "flex",
        flexDirection: "row",
        justifyItems: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: 40,
          height: 40,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderColor: "white",
          borderRadius: "50%",
          border: "solid",
          borderWidth: 1,
        }}
      >
        {props.icon()}
      </div>
      <div
        style={{
          paddingLeft: 8,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <a style={{ color: "white" }} target={"_blank"} href={props.link}>
          {props.title}
        </a>
      </div>
    </div>
  );
};

export default SocialMediaTab;
