import React from "react";
import { Col, Row } from "react-bootstrap";
import HeadingText from "./HeadingText";
import { FiPhone, FiMail, FiGlobe } from "react-icons/fi";
import { MdLocationOn } from "react-icons/md";

const ContactAndProfile = () => {
  return (
    <div>
      <Row style={{ margin: 0 }}>
        <Col
          style={{ paddingTop: 16, padding: 0, backgroundColor: "white" }}
          md={5}
        >
          {renderContact()}
        </Col>
        <Col
          md={7}
          style={{
            margin: 0,
            padding: 0,
          }}
        >
          {renderProfile()}
        </Col>
      </Row>
    </div>
  );
};

const renderProfile = () => {
  return (
    <div style={{}}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h4
          style={{
            alignSelf: "start",
            paddingRight: 16,
            paddingTop: 16,
            marginLeft: 16,
            color: "white",
          }}
        >
          PROFILE
        </h4>
        <hr
          style={{
            color: "white",
            alignSelf: "flex-start",
            width: 200,
            marginLeft: 16,
          }}
        />
        <p style={{ color: "white", padding: 16 }}>
          I am a Mobile Application developer who is focused on maximizing the
          performance of an applications within an efficient development time.
          For that reason, I am continuously learning new technologies,
          libraries, and architecture design patterns. I love to learn from
          other developers about their thought process, passions, and
          development skill to further improve myself. My interest is learning
          about different programming languages and standards such as
          JavaScript, Kotlin, Swift, React, React-Native. My skills in Android,
          IOS and also Hybrid (React Native), with experience in Project
          Management and designing, will be a great plus to your company. Also,
          I love to work with people and learn about their cultures and
          traditions. So if given the chance, I’d love to discuss further with
          you.
        </p>
      </div>
    </div>
  );
};

const renderContact = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h4 style={{ alignSelf: "center", paddingRight: 16, paddingTop: 16 }}>
        CONTACT
      </h4>
      <hr style={{ color: "black", alignSelf: "flex-end", width: 200 }} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <FiPhone style={{ color: "gray" }} />
            <p style={{ color: "gray", paddingLeft: 8 }}>+66 0990158836</p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <FiMail style={{ color: "gray" }} />
            <p style={{ color: "gray", paddingLeft: 8 }}>
              deeheinhtet@gmail.com
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <FiGlobe style={{ color: "gray" }} />
            <p style={{ color: "gray", paddingLeft: 8 }}>
              https://heinhtet.dev/
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <MdLocationOn style={{ color: "gray" }} />
            <p style={{ color: "gray", paddingLeft: 8 }}>Bangkok, Thailand</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactAndProfile;
