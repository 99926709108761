import React from "react";
import { ProgressBar } from "react-bootstrap";

const Skill = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: 16,
      }}
    >
      <h4 style={{ alignSelf: "center", paddingRight: 16, paddingTop: 16 }}>
        SKILLS
      </h4>
      <hr style={{ color: "black", alignSelf: "flex-end", width: 200 }} />
      <SkillItem title="Kotlin" progress={99} />
      <SkillItem title="JAVA" progress={99} />
      <SkillItem title="SWIFT" progress={70} />
      <SkillItem title="REACT NATIVE" progress={99} />
      <SkillItem title="JAVASCRIPT" progress={90} />
      <SkillItem title="TYPESCRIPT" progress={90} />
      <SkillItem title="LOGICAL THINKING" progress={99} />
      <SkillItem title="PROBLEM SOLVING" progress={99} />
    </div>
  );
};

interface Props {
  title: string;
  progress: number;
}
const SkillItem = (props: Props) => {
  return (
    <div>
      <h6 style={{ color: "black" }}>{props.title}</h6>
      <ProgressBar variant="black" now={props.progress} />
      <hr style={{ color: "white" }} />
    </div>
  );
};

export default Skill;
