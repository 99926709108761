import React from "react";
import { Container } from "react-bootstrap";
import ContactAndProfile from "./components/ContactAndProfile";
import EducationAndWork from "./components/EducationAndWork";
import Heading from "./components/Heading";
import RelatedProject from "./components/RelatedProject";
import SocialMedia from "./components/SocialMedia";
function App() {
  return (
    <div style={{ backgroundColor: "black" }}>
      <Container style={{ padding: 0 }}>
        <Heading />
        <SocialMedia />
        <ContactAndProfile />
        <EducationAndWork />
        <RelatedProject />
      </Container>
    </div>
  );
}

export default App;
